@import './components/imports.scss';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  font-size: 16px; /* Размер шрифта корневого элемента */
}

a{
  text-decoration: none;
  color: unset;
}


@font-face {
  font-family: 'Exo2';
  src: local('Exo2'), url(./fonts/Exo2-Bold.woff2) format('woff');
  font-weight: 700;

  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: local('Exo2'), url(./fonts/Exo2-Light.woff2) format('woff');
  font-weight: 200;

  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: local('Exo2'), url(./fonts/Exo2-Medium.woff2) format('woff');
  font-weight: 400;

  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: local('Exo2'), url(./fonts/Exo2-Regular.woff2) format('woff');
  font-weight: 300;

  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: local('Exo2'), url(./fonts/Exo2-SemiBold.woff2) format('woff');
  font-weight: 500;

  font-display: swap;
}

@font-face {
  font-family: 'Exo2';
  src: local('Exo2'), url(./fonts/Exo2-Thin.woff2) format('woff');
  font-weight: 100;

  font-display: swap;
}



@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-ExtraLight.woff) format('woff');
  font-weight: 100;

  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-Light.woff) format('woff');
  font-weight: 200;

  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-Regular.woff) format('woff');
  font-weight: 300;

  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-Medium.woff) format('woff');
  font-weight: 400;

  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-SemiBold.woff) format('woff');
  font-weight: 500;

  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(./fonts/Oswald-Bold.woff) format('woff');
  font-weight: 700;

  font-display: swap;
}



h1{
  font-weight: 700;
  font-size: 5rem;

  font-family: "Oswald";

@include breakpoint($mobile-bp){
  font-size: 3rem;
}
}

h1,h2,h3,h4,h5,h6{
  font-family: Oswald;
}

h2{
  font-size: 2rem;
}

.header-style{
  font-family: Oswald;
}


p{
  font-size: 1.2rem;
  font-weight: 200;
}


*{
  margin: unset;
}