@import './imports.scss';

.contacts-header{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.contacts-buttons{
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.contact-item{
    display: flex;
    gap: .5rem;

    font-size: 1rem;
}

.block-wrapper--contacts{
    gap: 4rem;
}

.contacts-container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}