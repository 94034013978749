@import './imports.scss';

footer{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;

    a{
        transition: all .2s ease-in-out;
        &:hover{
            color: $orange-medium;
        }
    }

    @include breakpoint($mobile-bp){
        flex-direction: column;
        gap: 2rem;
        padding-top: 2rem;
    }
}
.footer-logo{
    height: 3rem;
}


.footer-container{
    display: flex;
    flex-direction: column;
    gap: .7rem;
}


.footer-container__social-media{
    display: flex;
    font-size: 1rem;

    gap: .3rem;

    align-items: center;
}