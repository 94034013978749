@import './imports.scss';

.form-contacts {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    background-color: $white-grey;
    color: $black-grey;

    padding: 3rem;

    max-width: 35rem;

    @include breakpoint($mobile-bp) {
        padding: 1rem;
        background-color: rgba($color: $white-grey, $alpha: 0.8);
        backdrop-filter: blur(0.1rem);

        input{
            height: 1.5rem;
        }
    }

    
}

.form-contacts h3 {
    font-size: 2rem;
}

.form-contacts input {
    padding: 0.3rem;
}

.input-container {
    display: flex;
    flex-direction: column;

    label {
        display: flex;
        flex-direction: column;
    }
}

.home-background {
    input {
        @include breakpoint($mobile-bp) {
            height: 1.5rem;
        }
    }

    .header-style{
        @include breakpoint($mobile-bp){
            font-size: 1.5rem !important;
        }
    }

    .form-contacts{
        @include breakpoint($mobile-bp){
            gap: 1rem;
        }
    }
}
