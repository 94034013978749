@import './imports.scss';

nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;

    color: white;
 
    
    @include breakpoint($mobile-bp){
        flex-direction: column;
        gap: 3rem;
        font-size: 1.2rem;
    }
}

.open-menu-button{
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10;

    width: 3rem;
    height: 3rem;

    background-color: $orange-medium;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-nav{
    position: fixed;
    width: 100%;

    background-color: rgba($color:$dark-grey, $alpha: .5);

    backdrop-filter: blur(1rem);

    .block-wrapper{
        padding: 0;
    }

    z-index: 3;
}

nav img{

    max-height: 4rem;
}

.activeLink{
    font-weight: 500;
    text-decoration: underline;
    color: $orange-medium;
}

.menu__company-logo{
    height: 100%;
    width: 15rem;
}

.link{
    transition: all .2s ease-in-out;
    cursor: pointer;

 &:hover{
    color: $orange-medium;
 }
}