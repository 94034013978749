@import './imports.scss';

.form-block-background{
    background: url('../images/angleGrinderBackground.webp'), linear-gradient(#222222, #3a3a3a);
    background-blend-mode: overlay;



    display: flex;
    align-items: center;

    color: $light-grey; 

    @include breakpoint($mobile-bp){
        background-position: center center;

        background: url('../images/angleGrinderBackground.webp'), rgba($color:#222222, $alpha: .7 );
    }
}