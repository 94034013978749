@import './imports.scss';

.what-we-do-container{

}

.what-we-do-container__controls{
    display: grid;
    grid-template-columns: 2fr 1fr;

    gap: 2rem;
    align-items: center;

    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}


.swiper{
    // width: 300px;
}

.what-we-do-container .swiper {

}

.product-info__info-container{
    display: grid;
    grid-template-columns: 2fr 1fr;

    @include breakpoint($mobile-bp){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    
}


.slider-of-products-photo__photo{
   
    height: 30rem;
    background-position: center;
    background-size: cover;
    width: 100%;

    @include breakpoint($mobile-bp){
        height: 20rem;
    }
}

.buttons{
    display: flex;
    gap: 1rem;


    @include breakpoint($mobile-bp){
        flex-direction: column;
        justify-content: stretch;
        width: 100%;

        .btn{
            width: 100%;
        }
    }
}

.what-we-do-container__controls .btn{
    @include breakpoint($mobile-bp){
        padding: .7rem 2rem;
    }
    }

.swiper-pagination{
    position: relative;
    // background-color: $dark-grey;
    padding: .5rem 3rem;
    width: unset !important;
    border-radius: 1rem;
}

.swiper-pagination-bullet{

}

.swiper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    align-items: center;

    @include breakpoint($mobile-bp){
        gap: 1rem;
    }
}

.products-info .swiper-pagination-bullet{
    background-color: $dark-grey;
    opacity: .5;
}

.products-info .swiper-pagination-bullet-active{
    background-color: $orange-medium;
    opacity: 1;
}

.product-info__header{
    display: flex;
    gap: .7rem;
    align-items: center;

    .icon-container__icon{
        width: 3rem;
    }
}