@import './imports.scss';

.home-background {
    // background: url('../images/home-background.webp'), linear-gradient(#222222, #3a3a3a);

    // background-blend-mode: overlay;

    background-image: image-set(url('../images/home-background.webp') 1x, url('../images/home-background.webp') 2x);

    display: flex;
    align-items: center;

    color: $light-grey;
    position: relative;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        pointer-events: none;

        background: linear-gradient(#222222, #3a3a3a);
        opacity: 0.7;

        height: 100%;
        width: 100%;

        z-index: 1;
    }
}

.home-background > * {
    z-index: 2;
}

.block-wrapper--home {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;

    justify-items: flex-start;
    align-items: center;

    @include breakpoint($mobile-bp) {
        grid-template-columns: unset;
        grid-template-rows: 1fr 1fr;
        justify-items: unset;
        width: unset;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
    }
}

.block-whatwedo-background {
    background: $white-grey;
}

.home-background__logo-usm {
    height: 8rem;

    @include breakpoint($mobile-bp) {
        height: 5rem;
    }
}

.home-header {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;

    @include breakpoint($mobile-bp) {
        gap: 1rem;
        margin-bottom: 0;
    }
}

.home-info {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include breakpoint($mobile-bp) {
        gap: 2rem;
    }
}

.slogan {
    width: 21rem;

    @include breakpoint($mobile-bp){
        width: 15rem;
    }
}

.icon-container__icon {
    @include breakpoint($mobile-bp) {
        width: 75px;
        height: 75px;
    }
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 1.5rem;
    text-transform: lowercase;
    font-weight: 100;
    margin-top: 1.5rem;

    @include breakpoint($mobile-bp){
        gap: 0.5rem;
        margin-top: .5rem;
    }
}

.home-info ul {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
        list-style: none;

        padding-left: 0.1rem;
        position: relative;

        &::before {
            width: 0.7rem;
            height: 0.7rem;
            content: '';
            position: absolute;
            background-color: $orange-medium;

            left: -1.5rem;
            top: 35%;
        }
    }
}
