@import './imports.scss';

.btn {
    padding: 1rem 5rem;
    transition: all 0.3s ease-in-out;
    font-weight: 700;
    cursor: pointer;

    font-size: 1rem;
    font-weight: 300;
    

    border: 1px solid $dark-grey;

    transform: scale(1);

    &--sm{
        padding: .5rem 2rem;
    }

    &--black-grey {
        background-color: $black-grey;
        color: $light-grey;
        border: none;
        border: 1px solid black;

        
    }

    &--light-grey {
        background-color: $light-grey;
    }

    &--white{
        background-color: $white-grey;
        border: $white-grey 1px solid;
        color: black;

        &--bd-black{
            border: 1px solid black;
        }
    }

    &:hover {
        background-color: $orange-medium;
        color: black;
        border: 1px solid $orange-medium;
        // transform: scale(1.1);
    }

    &--orange{
        background: $orange-medium;
        border: 1px solid $orange-medium;
        color: black;

        
    }

    &--inactive{
        opacity: .3;
        transform: scale(0.95);

        transition: all 0.3s ease-in-out;
    }
}
