@import './components/imports.scss';

.block-wrapper{
  max-width: 1600px;
  margin: 0 auto;

  padding: 2rem 3rem;

  @include breakpoint($mobile-bp){
    padding: .5rem;
  }
}

.block-background{
  min-height: 100vh;

  padding: 3rem 0;
  box-sizing: border-box;

  background-size: cover !important;
  background-repeat: no-repeat !important;

  @include breakpoint($mobile-bp){
    padding: 1.5rem 0;
  }
}